<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <b-img
            height="50"
            width="100"
            :src="require('@/assets/images/logo/logo.jpg')"
            alt="Register V2"
          />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        style="background-color:#2c3d57 !important"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-top auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            
            Enter your email and we'll send you Code to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules" v-if="!sendCode">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Send Code
              </b-button>
            </b-form>
          </validation-observer>
          <validation-observer ref="simpleRulescode" v-if="sendCode">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationFormCode"
            >
              <b-form-group
               
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Code"
                  rules="required"
                >
                  <b-form-input
                    id="Code"
                    v-model="code"
                    :state="errors.length > 0 ? false:null"
                    name="Code"
                    placeholder="Enter Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Check Code
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      sendCode:false,
      email,
      code:''
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationFormCode(){
      
      this.$refs.simpleRulescode.validate().then(success => {
        if (success) {
          const formData = new FormData();
          formData.append("token", this.code);
          formData.append("email", this.userEmail);
          useJwt.checkCode(formData)
            .then(response => {
              if(response.data.status==200){
              this.$router.push('/reset-password')
              console.log("eee",response)
              }else{
                this.$swal({
                title: "",
                text: `${response.data.message}`,
                icon: "error",
                confirmButtonText: "ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              }

            })
          
        
    }})
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          
          const formData = new FormData();
          formData.append("email", this.userEmail);
          useJwt.resetEmail(formData)
            .then(response => {
              if(response.data.status==200){
              localStorage.setItem('userEmail',this.userEmail)
              this.sendCode=true
              console.log("eee",response)
              this.$swal({
                title: "",
                text: `Code has been sent to ${this.userEmail}`,
                icon: "success",
                confirmButtonText: "ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }

            }).catch((error) => {
              
              this.$swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
          
        
    }})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
